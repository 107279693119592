/*colors*/
$g-color-white: #fff;
$g-color-blue: #3498db;
$g-color-dark-gray: #0c0c0c;
$g-color-light-gray: #c7d0d8;
$g-color-red: #db3434;
$g-color-green: #67db34;

$g-colors:(white: $g-color-white,
    blue: $g-color-blue,
    dark-gray: $g-color-dark-gray,
    light-gray: $g-color-light-gray);

$g-bg-colors:(white: $g-color-white,
    blue: $g-color-blue,
    dark-gray: $g-color-dark-gray,
    light-gray: $g-color-light-gray);



$g-text-color-light: $g-color-white;
$g-text-color-dark: $g-color-dark-gray;

$g-link-color-light: $g-color-white;
$g-link-color-dark: $g-color-dark-gray;

$g-link-color-hover-light: mix($g-color-blue, $g-color-white, 40%);
$g-link-color-hover-dark: mix($g-color-blue, $g-color-white, 80%);

$g-background-color-light: $g-color-white;
$g-background-color-dark: $g-color-blue;


$g-basic-text-color: $g-text-color-dark;
$g-basic-h1-color: $g-color-blue;
$g-basic-hx-color: $g-text-color-dark;
$g-basic-link-color: $g-link-color-dark;
$g-basic-link-hover-color: $g-link-color-hover-dark;
$g-basic-background-color: $g-background-color-light;
$g-basic-error-color: $g-color-red;
$g-basic-correct-color: $g-color-green;
$g-basic-button-color: $g-text-color-dark;

/*fonts*/
$g-font-primary: 'Kanit',
sans-serif;
$g-font-secondary: 'Kanit',
sans-serif;

$g-font-size-huge: 32px;
$g-font-size-x-large: 28px;
$g-font-size-large: 26px;
$g-font-size-x-medium: 24px;
$g-font-size-medium: 18px;
$g-font-size-normal: 16px;
$g-font-size-small: 14px;
$g-font-size-x-small: 12px;
$g-font-size-xx-small: 11px;
$g-font-size-xxx-small: 10px;

$g-font-weight-light: 300;
$g-font-weight-regular: 400;
$g-font-weight-medium: 500;
$g-font-weight-bold: 700;
$g-font-weight-extra-bold: 800;
$g-letter-spacing: 1px;
$g-letter-spacing-medium: 2px;


/*margin*/
$g-margin-x__5: 0.05em;
$g-margin-x_25: 0.25em;
$g-margin-x_5: 0.5em;
$g-margin-x_75: 0.75em;
$g-margin-x1: 1em;
$g-margin-x1_5: 1.5em;
$g-margin-x2: 2em;
$g-margin-x3: 3em;
$g-margin-x5: 5em;

/*padding*/
$g-padding-x_25: 0.25em;
$g-padding-x_5: 0.5em;
$g-padding-x_75: 0.75em;
$g-padding-x1: 1em;
$g-padding-x1_5: 1.5em;
$g-padding-x2: 2em;
$g-padding-x3: 3em;
$g-padding-x5: 5em;

$g-padding-5px: 0.3125em;
$g-padding-10px: 0.625em;
$g-padding-12px: 0.75em;
$g-padding-15px: 0.9375em;
$g-padding-20px: 1.25em;
$g-padding-24px: 1.5em;
$g-padding-25px: 1.5625em;
$g-padding-30px: 1.875em;
$g-padding-35px: 2.1875em;
$g-padding-37px: 2.3125em;
$g-padding-40px: 2.5em;
$g-padding-45px: 2.8125em;
$g-padding-50px: 3.125em;
$g-padding-55px: 3.4375em;
$g-padding-60px: 3.75em;
$g-padding-70px: 4.375em;
$g-padding-74px: 4.625em;

// Tiny
// Small
// Normall
// Large
// Huge
$g-padding-l: $g-padding-74px;
$g-padding-n: $g-padding-37px;
$g-padding-s: $g-padding-30px;
$g-padding-t: $g-padding-x1;

/*breakpoints*/
$g-breakpoints: (large-plus: 1274px,
    large: 1200px,
    x-medium: 980px,
    medium: 800px,
    normal: 768px,
    small: 640px,
    x-small: 480px,
    xx-small: 360px,
);


/*grid*/
$g-width-x_1: 0.1em;
$g-width-x_15: 0.15em;
$g-width-x_25: 0.25em;
$g-width-x_5: 0.5em;
$g-width-x_75: 0.75em;
$g-width-x1: 1em;
$g-width-x1_5: 1.5em;
$g-min-width: 320px;

$g-medium-width: 1052px;
$g-max-width: 1200px;
$g-huge-width: 1274px;
$g-min-height: 1em;
$g-min-height-bar: $g-padding-74px;
$g-min-height-home: 600px;
$g-min-height-map: 420px;
$g-min-height-img: 420px;

/*borders*/
$g-basic-border-width: 2px;
$g-basic-border-style: solid;
$g-basic-border-color: $g-basic-text-color;

/*button*/
$g-button-colors: (ghost-white: (color: #ffffff, bg-color:transparent, border-color:#ffffff, bg-color-hover:mix(#009036, $g-color-white, 90%)),
    ghost-blue: (color: #633368, bg-color:transparent, border-color:#633368, bg-color-hover:mix(#633368, $g-color-white, 75%)),
    ghost-light-gray: (color: #aaaaaa, bg-color:transparent, border-color:#d9d9d9, bg-color-hover:mix(#d9d9d9, $g-color-white, 75%)),
    ghost-green: (color: #009036, bg-color:transparent, border-color:#009036, bg-color-hover:mix(#009036, $g-color-white, 90%)),
    ghost-dark-gray: (color: #353236, bg-color:transparent, border-color:#353236, bg-color-hover:mix(#353236, $g-color-white, 75%)),
    white: (color: $g-color-blue, bg-color:#ffffff, border-color:#ffffff, bg-color-hover:$g-color-white),
    blue: (color: #ffffff, bg-color:$g-color-blue, border-color:$g-color-blue, bg-color-hover:mix($g-color-blue, $g-color-white, 75%)),
    green: (color:#ffffff, bg-color: $g-color-green, border-color:$g-color-green, bg-color-hover:mix($g-color-green, $g-color-dark-gray, 75%)),
    light-gray: (color: #666666, bg-color:#d9d9d9, border-color:#d9d9d9, bg-color-hover:mix(#d9d9d9, $g-color-white, 75%)),
    dark-gray: (color: #ffffff, bg-color:#353236, border-color:#353236, bg-color-hover:mix(#353236, $g-color-white, 75%)),
    transparent: (color:#353236, bg-color: transparent, border-color:transparent, bg-color-hover:mix(#009036, $g-color-white, 90%)));
$g-button-font-size: 12px;
$g-button-size: 18px;
$g-button-size-small: 14px;
$g-button-size-big: 22px;
$g-button-line-height: 2.5;
$g-button-color: map-get($g-button-colors, light-gray);

$g-button-colors-icon:(white: (color: $g-color-white, icon-color: $g-color-blue, bg-icon-color:#ffffff, color-hover:mix($g-color-dark-gray, $g-color-white, 15%)),
    blue: (color: $g-color-dark-gray, icon-color: $g-color-white, bg-icon-color:$g-color-blue, color-hover:mix($g-color-dark-gray, $g-color-white, 75%)),
    green: (color: $g-color-dark-gray, icon-color: $g-color-white, bg-icon-color: $g-color-green, color-hover:mix($g-color-dark-gray, $g-color-white, 75%)));

/*icon*/
$g-icon-size: 52px;
$g-icon-font-size: 24px;
$g-icon-line-height: $g-icon-size;
$g-icon-color: map-get($g-button-colors, blue);