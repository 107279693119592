@function calculate-rem($size) {
    $rem-size: $size / 16px;
    @return #{$rem-size}rem;
}

@mixin font-size($size:$g-font-size-normal) {
    font-size: $size;
    font-size: calculate-rem($size);
}

@mixin font-weight($font-weight: $g-font-weight-regular) {
    font-weight: $font-weight;
}

@mixin font-basic-size {
    @include font-size($g-font-size-normal);
}

@mixin font-basic-style {
    font-family: $g-font-secondary;
    font-weight: $g-font-weight-regular;
    color: $g-basic-text-color;
    line-height: 1.5;
    hyphens: none;
}

@mixin font-h1-basic-style {
    font-family: $g-font-primary;
    font-weight: $g-font-weight-light;
    color: $g-basic-h1-color;
    line-height: 1.3;
    hyphens: none;
}

@mixin font-hx-basic-style {
    font-family: $g-font-primary;
    font-weight: $g-font-weight-regular;
    color: $g-basic-hx-color;
    line-height: 1.3;
    hyphens: none;
}