.c-alert {
    font-weight: $g-font-weight-regular;
    display: inline-block;
    position: relative;
    padding: $g-padding-x1;

    &__error {
        @include color($g-basic-text-color);
        @include background-color($g-basic-background-color);
        border-radius: calculate-rem(1.5*$g-button-size);
        border: $g-basic-border-width $g-basic-border-style $g-basic-text-color;
        border-color: $g-basic-error-color;
        padding: $g-padding-x1;
        display: inline-block;

    }

    &__correct {
        @include color($g-basic-text-color);
        @include background-color($g-basic-background-color);
        border-radius: calculate-rem(1.5*$g-button-size);
        border: $g-basic-border-width $g-basic-border-style $g-basic-text-color;
        border-color: $g-basic-correct-color;
        padding: $g-padding-x1;
        display: inline-block;
    }
}