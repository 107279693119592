.align {
	&--left {
		float: left;
		margin: $g-margin-x_5 $g-margin-x_5 $g-margin-x_5 0;
	}

	&--right {
		float: right;
		margin: $g-margin-x_5 0 $g-margin-x_5 $g-margin-x_5;
	}

	&--center {
		clear: both;
		display: block;
		margin: 0 auto $g-margin-x_5 auto;
	}

	&--none {
		margin: 0 0 $g-margin-x_5 0;
	}

}

.float {
	&--left {
		float: left;
	}

	&--right {
		float: right;
	}

	&--clear {
		clear: both;
	}
}