.c-list {
	list-style: none;
	padding: 0;
	margin: 0;

	&__item {
		position: relative;
		padding-left: $g-padding-x1_5;
		margin-bottom: $g-margin-x_5;

		&:before {
			position: absolute;
			content: "";
			width: $g-width-x_75;
			height: $g-width-x_15;
			background-color: $g-color-blue;
			top: $g-width-x_5;
			left: 0;
		}
	}
}





// ul,
// ol,
// dd {
// 	margin: $g-margin-basic;
// 	padding: 0;

// 	p {
// 		padding-left: $g-padding-10px;
// 	}
// }

// ul {
// 	list-style-type: disc;
// 	padding-left: $g-padding-10px;

// 	ul {
// 		list-style-type: circle;
// 	}
// }

// ol {
// 	padding-left: $g-padding-10px;
// 	margin-left: 0;

// }

// li>ul,
// li>ol {
// 	margin-bottom: 0;
// 	padding-left: $g-padding-20px;
// }

// li {
// 	padding: 0;
// 	padding-bottom: $g-padding-5px;
// }