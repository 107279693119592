@mixin section-flex-basic-style {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
}

// @mixin section-flex-bg-basic-style {
//     @include section-flex-basic-style;
//     flex: 1;
// }

@mixin section-basic-style($padding: $g-padding-n) {
    width: 100%;
    min-width: $g-min-width;
    position: relative;
    clear: both;
    overflow: visible;
    margin: 0 auto;
    padding: $padding;
    max-width: $g-max-width;

    // @include breakpoint-max('medium') {
    //     padding: $g-padding-n;

    // }

    // @include breakpoint-max('small') {
    //     padding: $g-padding-s;

    // }
}

@mixin section-sticky-basic-style {
    position: fixed;
    top: 0;
    bottom: auto;
    z-index: 999;
}


/*section*/
.l-section {
    $parent: &;
    @include section-basic-style($padding: $g-padding-l);
        @include breakpoint-max('medium') {
           padding: $g-padding-n

        }

        @include breakpoint-max('small') {
           padding: $g-padding-s

        }

    &--top {
        padding-bottom: 0;

        @include breakpoint-max('medium') {
            padding-bottom: 0;

        }

        @include breakpoint-max('small') {
            padding-bottom: 0;

        }
    }

    &--bottom {
        padding-top: 0;

        @include breakpoint-max('medium') {
            padding-top: 0;

        }

        @include breakpoint-max('small') {
            padding-top: 0;

        }
    }



    /*n-normal*/
    &--normal {
        padding: $g-padding-n;

        @include breakpoint-max('medium') {
            padding: $g-padding-n;

        }

        @include breakpoint-max('small') {
            padding: $g-padding-n;

        }

        &#{$parent}--top {
            padding-bottom: 0;

            @include breakpoint-max('medium') {
                padding-bottom: 0;

            }

            @include breakpoint-max('small') {
                padding-bottom: 0;

            }
        }

        &#{$parent}--bottom {
            padding-top: 0;

            @include breakpoint-max('medium') {
                padding-top: 0;

            }

            @include breakpoint-max('small') {
                padding-top: 0;

            }
        }
    }

    /*s-small*/
    &--small {
        padding: $g-padding-s;

        @include breakpoint-max('medium') {
            padding: $g-padding-s;

        }

        @include breakpoint-max('small') {
            padding: $g-padding-s;

        }

        &#{$parent}--top {
            padding-bottom: 0;

            @include breakpoint-max('medium') {
                padding-bottom: 0;

            }

            @include breakpoint-max('small') {
                padding-bottom: 0;

            }
        }

        &#{$parent}--bottom {
            padding-top: 0;

            @include breakpoint-max('medium') {
                padding-top: 0;

            }

            @include breakpoint-max('small') {
                padding-top: 0;

            }
        }

    }

    /*t-tiny*/
    &--tiny {
        padding: $g-padding-t;

        @include breakpoint-max('medium') {
            padding: $g-padding-t;

        }

        @include breakpoint-max('small') {
            padding: $g-padding-t;

        }

        &#{$parent}--top {
            padding-bottom: 0;

            @include breakpoint-max('medium') {
                padding-bottom: 0;

            }

            @include breakpoint-max('small') {
                padding-bottom: 0;

            }
        }

        &#{$parent}--bottom {
            padding-top: 0;

            @include breakpoint-max('medium') {
                padding-top: 0;

            }

            @include breakpoint-max('small') {
                padding-top: 0;

            }
        }

    }



    /*t-tiny 0*/
    &--tiny-0 {
        padding: $g-padding-t 0;

        @include breakpoint-max('medium') {
            padding: $g-padding-t 0;

        }

        @include breakpoint-max('medium') {
           padding: $g-padding-n

        }

        @include breakpoint-max('small') {
           padding: $g-padding-s

        }


        &#{$parent}--top {
            padding-bottom: 0;

            @include breakpoint-max('medium') {
                padding-bottom: 0;

            }

            @include breakpoint-max('small') {
                padding-bottom: 0;

            }
        }

        &#{$parent}--bottom {
            padding-top: 0;

            @include breakpoint-max('medium') {
                padding-top: 0;

            }

            @include breakpoint-max('small') {
                padding-top: 0;

            }
        }
    }

    &--0 {
        padding: 0;

        @include breakpoint-max('medium') {
            padding: 0;

        }

        @include breakpoint-max('small') {
            padding: 0;

        }
    }

    /*width*/
    &--medium {
        max-width: $g-medium-width;
    }

    &--huge {
        max-width: $g-huge-width;
    }

    /*height*/
    &--max-height {
        height: inherit;
        min-height: inherit;
    }

    &--min-height {
        min-height: $g-min-height-bar;
    }

    &--center {
        text-align: center;
    }

    &--left {
        text-align: left;
    }

    &--right {
        text-align: right;
    }

}

.l-items {
    @include section-flex-basic-style;

    &--col {
        flex-direction: column;
    }

    &--col-rev {
        flex-direction: column-reverse;
    }

    @include breakpoint-max(small) {
        &--mobile-col-rev {
            flex-direction: column-reverse;
        }
    }

    &--center {
        justify-content: center;
    }

    &--left {
        justify-content: flex-start;
    }

    &--right {
        justify-content: flex-end;
    }

    &--between {
        justify-content: space-between;
    }

    &--v-top {
        align-items: flex-start;
    }

    &--v-bottom {
        align-items: flex-end;
    }

    &--v-center {
        align-items: center;
    }
}


.l-items__item {

    &--large {
        padding: $g-padding-l;

                    @include breakpoint-max('x-medium') {
                padding: $g-padding-n;

            }
                    @include breakpoint-max('medium') {
                padding: $g-padding-s;

            }

            @include breakpoint-max('small') {
                padding: $g-padding-t;

            }
    }

    &--normal {
        padding: $g-padding-n;
    }

    &--small {
        padding: $g-padding-s;
    }

    &--tiny {
        padding: $g-padding-t;
    }

    &--large-small {
        padding: $g-padding-l $g-padding-s;
    }

    &--normal-small {
        padding: $g-padding-n $g-padding-s;
    }

    &--tiny-small {
        padding: $g-padding-t $g-padding-s;

    }

    &--start {
        align-self: flex-start;
        margin-right: auto;
    }

    &--end {
        align-self: flex-end;
        margin-left: auto;
    }

    &--1-2 {
        width: 50%;
    }

    &--1-3 {
        width: 33.3333%;
        width: calc(100% * 1/3);
    }

    &--2-3 {
        width: 66.6666%;
        width: calc(100% * 2/3);
    }

    &--1-4 {
        width: 25%;
    }

    &--3-4 {
        width: 75%;
    }

    @include breakpoint-max(small) {
        &--1-2 {
            width: 100%;
            padding-left: 0px;
            padding-right: 0px;
        }

        &--1-3 {
            width: 100%;
            padding-left: 0px;
            padding-right: 0px;
        }

        &--2-3 {
            width: 100%;
            padding-left: 0px;
            padding-right: 0px;
        }

        &--1-4 {
            width: 100%;
            padding-left: 0px;
            padding-right: 0px;
        }

        &--3-4 {
            width: 100%;
            padding-left: 0px;
            padding-right: 0px;
        }
    }

}




.l-section-bg {
    max-width: none;

    &--photo-1 {
        min-height: $g-min-height-img;
        @include bg-cover;
        background-color: $g-color-dark-gray;
        background-image: url(../images/img-1.jpg);
    }

    &--max-height {
        height: 100%;
        height: 100vh;
    }

    &--top-shadow {
        @include bg-top-shadow;
    }

    @each $name,
    $bg-color in $g-bg-colors {
        &--#{$name} {
            @include background-color($bg-color);
            color: set-text-color($bg-color);

            & .o-link {
                color: set-link-color($bg-color);

                &:hover {
                    color: set-link-hover-color($bg-color);
                }
            }

            & .c-header__subtitle {
                color: set-hx-color($bg-color);
            }

            & .c-header__title {
                color: set-h1-color($bg-color);
            }

            &-transparent {
                @include background-color($bg-color, .8);
                color: set-text-color($bg-color);
            }
        }
    }



}



.l-section-paralax {
    background-attachment: scroll;
    background-attachment: fixed !important;
    @include bg-cover;

    &--photo-1 {
        background-image: url(../images/img-1.jpg);
    }

    &--photo-2 {
        background-image: url(../images/img-1.jpg);
        padding-bottom: $g-min-height-img;
    }
}



// .l-section-bg {
//     @include section-basic-style;
//     @include section-flex-basic-style;
//     max-width: none;
//     flex: 1;
//     $parent: &;

//     &__content {
//         &--1-2 {
//             width: 50%;

//             @include breakpoint-min(large) {
//                 &#{$parent}--left {
//                     justify-content: flex-start;
//                     width: 50%;
//                     width: calc(50% - 300px);
//                     padding-left: calc(50% - 300px);
//                     padding-right: 0;
//                 }

//                 &#{$parent}--right {
//                     justify-content: flex-start;
//                     width: 50%;
//                     width: calc(50% - 300px);
//                     padding-right: calc(50% - 300px);
//                     padding-left: 0;
//                 }
//             }
//         }

//         &--1-3 {
//             width: 33.3333%;
//             width: calc(100% * 1/3);

//             @include breakpoint-min(large) {
//                 &#{$parent}--left {
//                     justify-content: flex-start;
//                     width: 50%;
//                     width: calc(50% - 200px);
//                     padding-left: calc(50% - 600px);
//                     padding-right: 0;
//                 }

//                 &#{$parent}--right {
//                     justify-content: flex-start;
//                     width: 50%;
//                     width: calc(50% - 200px);
//                     padding-right: calc(50% - 600px);
//                     padding-left: 0;
//                 }
//             }
//         }

//         &--2-3 {
//             width: 66.6666%;
//             width: calc(100% * 2/3);

//             @include breakpoint-min(large) {
//                 &#{$parent}--left {
//                     justify-content: flex-start;
//                     min-height: 100%;
//                     width: 50%;
//                     width: calc(50% + 200px);
//                     padding-left: calc(50% - 600px);
//                     padding-right: 0;
//                 }

//                 &#{$parent}--right {
//                     justify-content: flex-start;
//                     min-height: 100%;
//                     width: 50%;
//                     width: calc(50% + 200px);
//                     padding-right: calc(50% - 600px);
//                     padding-left: 0;
//                 }
//             }
//         }

//         &--1-4 {
//             width: 25%;
//         }

//         &--3-4 {
//             width: 75%;
//         }

//         @include breakpoint-max(small) {
//             &--1-2 {
//                 width: 100%;
//             }

//             &--1-3 {
//                 width: 100%;
//             }

//             &--2-3 {
//                 width: 100%;
//             }

//             &--1-4 {
//                 width: 100%;
//             }

//             &--3-4 {
//                 width: 100%;
//             }
//         }


//     }


//     &--img-1 {
//         min-height: $g-min-height-img;
//         @include bg-cover;
//         background-color: $g-color-dark-gray;
//         background-image: url(../images/img-1.jpg);

//     }


//     &--max-height {
//         height: 100%;
//         height: 100vh;
//     }

//     @each $name,
//     $bg-color in $g-bg-colors {
//         &--bg-#{$name} {
//             @include background-color($bg-color);
//             color: set-text-color($bg-color);

//             &-transparent {
//                 @include background-color($bg-color, .8);
//             }
//         }
//     }
// }