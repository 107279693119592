@mixin breakpoint-max($size) {
  @if map-has-key($g-breakpoints, $size) {
    @media only screen and (max-width: #{map-get($g-breakpoints, $size)-1}) {
      @content;
    }
  }
}

@mixin breakpoint-min($size) {
  @if map-has-key($g-breakpoints, $size) {
    @media only screen and (min-width: #{map-get($g-breakpoints, $size)}) {
      @content;
    }
  }
}

@mixin breakpoint-max-o($size, $orientation) {
  @if map-has-key($g-breakpoints, $size) {
    @media only screen and (max-width: #{map-get($g-breakpoints, $size)-1})and (orientation: $orientation) {
      @content;
    }
  }
}

@mixin breakpoint-min-o($size, $orientation) {
  @if map-has-key($g-breakpoints, $size) {
    @media only screen and (min-width: #{map-get($g-breakpoints, $size)}) and (orientation: $orientation) {
      @content;
    }
  }
}

@mixin breakpoint-between($size-up, $size-down) {
  @if map-has-key($g-breakpoints, $size-up) and map-has-key($g-breakpoints, $size-down) {
    @media only screen and (min-width: #{map-get($g-breakpoints, $size-up)}) and (max-width: #{map-get($g-breakpoints, $size-down)-1}) {
      @content;
    }
  }

}

@mixin breakpoint-between-o($size-up, $size-down, $orientation) {
  @if map-has-key($g-breakpoints, $size-up) and map-has-key($g-breakpoints, $size-down) {
    @media only screen and (min-width: #{map-get($g-breakpoints, $size-up)}) and (max-width: #{map-get($g-breakpoints, $size-down)-1}) and (orientation: $orientation) {
      @content;
    }
  }

}