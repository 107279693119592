.c-header-menu {
    @include section-flex-basic-style;
    justify-content: flex-end;
    list-style: none;
    margin: 0;
    text-align: center;
    padding: 0;

    &__item {
        display: inline-block;
        padding: 0;
        margin: 0;

        &--active>a {
            color: $g-link-color-hover-dark;
        }
    }

    &__item-lang {
        @include section-flex-basic-style;
        justify-content: center;
        margin: 0;


        @include breakpoint-max('x-medium') {
            margin-top: $g-margin-x2;
        }

        & .o-button {
            margin-left: $g-padding-10px;

            @include breakpoint-max('x-medium') {
                margin-left: $g-margin-x_5;
                margin-right: $g-margin-x_5;
            }
        }

        & .o-button--active {
            cursor: default;
        }

    }


    &__link {
        display: block;
        @include font-size;
        font-family: $g-font-primary;
        @include color($g-link-color-dark);
        padding: $g-padding-10px;
        position: relative;
        text-align: center;
        text-decoration: none;
        font-weight: $g-font-weight-regular;

        @include breakpoint-min(x-large) {
            & {
                @include font-size(20px);
            }
        }

        &:hover {
            @include color($g-link-color-hover-dark);
        }
    }

    @include breakpoint-max('x-medium') {
        flex-direction: column;
        justify-content: flex-start;
        flex-wrap: wrap;
        width: 300px;
        height: 100vh;
        min-height: 600px;
        position: fixed;
        top: 0;
        right: -300px;
        background-color: $g-color-white;
        transition: transform 1s;
        padding-top: 4em;
    }

    &.js-menu-open {

        @include breakpoint-max('x-medium') {
            transform: translateX(-300px);
            overflow-y: scroll;
            visibility: visible;

        }


    }

}

.o-menu-bg {
    position: fixed;
    z-index: 10;
    top: 0;
    right: -100%;
    width: 100%;
    height: 100%;
    transition: transform 1s;
    background: rgba($g-color-white, 0.8);

    @at-root .js-menu-active & {
        transform: translateX(-100%);
    }
}

.js-menu-active {
    position: relative;
    overflow-y: hidden;
    /* height: auto; */
    z-index: 1 !important;
}

.c-header-navbar {
    z-index: 999;
    position: absolute;
    width: 100%;


    &--sticky {}

}



.c-header-menu-switcher-content {
    position: relative;
    z-index: 99;
    display: none;

    @include breakpoint-max('x-medium') {
        display: -webkit-flexbox;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: -moz-flex;
        display: flex;
        -webkit-flex-direction: row;
        -moz-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-end;
        align-items: baseline;
    }
}

.c-header-menu-switcher {
    display: -webkit-flexbox;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: -moz-flex;
    display: flex;
    -webkit-flex-direction: row;
    -moz-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: center;
    cursor: pointer;
    height: 2em;

}

%hamburger {
    content: '';
    top: 0;
    left: 0;
    width: 2em;
    height: 2px;
    background-color: $g-color-dark-gray;
    position: relative;
    transition: all 0.5s;
    display: inline-block;
}

.o-menu-hamburger {
    @extend %hamburger;
    margin-left: $g-margin-x_5;

    &:before {
        @extend %hamburger;
        top: -12px;
        position: absolute;

        @at-root .js-menu-switcher-open & {
            top: 0;
            transform: rotate(45deg);
            background-color: $g-color-blue;
        }
    }

    &:after {
        @extend %hamburger;
        top: 12px;
        position: absolute;

        @at-root .js-menu-switcher-open & {
            top: 0px;
            transform: rotate(-45deg);
            background-color: $g-color-blue;
        }
    }

    @at-root .js-menu-switcher-open & {
        background-color: transparent;
    }
}



// @include breakpoint-max(large) {
//     .main-menu-bg {
//         min-height: 100%;
//         padding: 0;
//         position: fixed;
//         right: 0;
//         top: 0;
//         width: 100%;
//         z-index: 99;
//         background: rgba($color-dark-blue, .7);
//         display: none;
//         opacity: 0;
//         height: 100%;
//     }

//     body.body-menu-open {
//         overflow: hidden;
//     }
//     .main-menu_wrapper {
//         width: 100%;
//     }
//     #main-menu {
//         .menu-open & {
//             display: block;
//             height: auto;
//             width: 100%;
//             padding-left: $g-padding-g25;
//             padding-right: $g-padding-g25;
//         }

//         li {
//             display: block;
//             float: none;
//             margin: 0;
//             position: relative;
//             width: 100%;

//             a {
//                 padding: $g-padding-x05 $g-padding-g10;
//                 text-align: left;
//             }
//         }
//     }
// }

// .main-menu-toggle-close_wrapper {
//     display: none;
//     padding: $g-padding-g05 $g-padding-g10;

//     @include breakpoint-max(large) {
//         & {
//             display: inline-block;
//         }
//     }
// }

// .main-menu-toggle-open_wrapper {
//     display: none;
//     padding: $g-padding-g05 $g-padding-g10;
//     margin-left: auto;

//     @include breakpoint-max(large) {
//         & {
//             display: inline-block;
//         }
//     }

//     @include breakpoint-max(large) {
//         & {
//             position: absolute;
//             right: 0;
//             bottom: $g-padding-g05;
//         }

//         .nav-sticky & {
//             position: relative;
//             right: auto;
//             bottom: auto;
//         }
//     }
// }

// .main-menu-toggle .button-line-point {
//     @include border-basic-style($color-white);
//     @include color($color-dark-blue);

//     &:hover {
//         @include color();
//         @include background-color($color-dark-blue);
//         @include border-basic-style($color-dark-blue);

//     }
// }

// .main-menu-toggle {
//     cursor: pointer;
// }