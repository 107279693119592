.o-link {
	text-decoration: underline;
	transition: color 0.5s;
	color: set-link-color($g-color-white);

	&:hover,
	&:active {
		text-decoration: none;
		color: lighten(set-link-color($g-color-white), 10%);
		outline: 0;
	}

	&:focus {
		outline: 0;
		text-decoration: none;
	}
}

@each $name,
$color in $g-bg-colors {
	.bg {
		&-#{$name} {
			@if $color !=white and $color !=black {
				&-light {
					a:not(.button) {
						color: set-link-color(lighten($color, 10%));

						&:hover,
						&:active {
							color: lighten(set-link-color(lighten($color, 10%)), 10%);
						}
					}
				}

				&-dark {
					a:not(.button) {
						color: set-link-color(darken($color, 10%));

						&:hover,
						&:active {
							color: lighten(set-link-color(darken($color, 10%)), 10%);
						}
					}
				}
			}

			a:not(.button) {
				color: set-link-color($color);

				&:hover,
				&:active {
					color: lighten(set-link-color($color), 10%);
				}
			}
		}
	}
}