.u-center {
    text-align: center;
}

.u-left {
    text-align: left;
}

.u-right {
    text-align: right;
}

.u-justify {
    text-align: justify;
}

.u-uppercase {
    text-transform: uppercase;
}

.u-capitalize {
    font-variant: small-caps;
}

.u-letter-spacing {
    letter-spacing: 1px;
}