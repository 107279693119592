@mixin color($color: $g-basic-text-color) {
  color: $color;
}

@function set-text-color($bg-color) {
  @if (lightness($bg-color) > 70) {
    @return $g-text-color-dark;
  }

  @else {
    @return $g-text-color-light;
  }
}

@function set-h1-color($bg-color) {
  @if (lightness($bg-color) > 70) {
    @return $g-color-blue;
  }

  @else {
    @return $g-text-color-light;
  }
}

@function set-hx-color($bg-color) {
  @if (lightness($bg-color) > 70) {
    @return $g-basic-hx-color;
  }

  @else {
    @return $g-text-color-light;
  }
}

@function set-link-color($bg-color) {
  @if (lightness($bg-color) > 70) {
    @return $g-link-color-dark;
  }

  @else {
    @return $g-link-color-light;
  }
}

@function set-link-hover-color($bg-color) {
  @if (lightness($bg-color) > 70) {
    @return $g-link-color-hover-dark;
  }

  @else {
    @return $g-link-color-hover-light;
  }
}