*,
*:before,
*:after {
	box-sizing: border-box;
}

.l-html {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	padding: 0;
	margin: 0;
}

.l-wrapper {
	padding: 0;
	width: 100%;
	margin: 0 auto;
	min-width: $g-min-width;
	@include font-size($size:$g-font-size-normal);
	@include font-basic-style;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
	display: block;
}

// h1,
// h2,
// h3,
// h4,
// h5,
// h6 {
// 	@include font-hx-basic-style;
// 	clear: both;
// 	margin: 0;
// 	padding: $g-padding-x_5 0 $g-padding-x1 0;

// 	@include breakpoint-max(x-small) {
// 		padding: $g-padding-x_5 0 $g-padding-x_5 0;
// 	}
// }


// h1 {
// 	@include font-size(28px);

// 	@include breakpoint-max(small) {
// 		@include font-size(26px);
// 	}
// }

// h2 {
// 	@include font-size(24px);

// 	@include breakpoint-max(small) {
// 		@include font-size(22px);
// 	}
// }

// h3 {
// 	@include font-size(18px);

// 	@include breakpoint-max(small) {
// 		@include font-size(16px);
// 	}
// }

// h4 {
// 	@include font-size(16px);

// 	@include breakpoint-max(small) {
// 		@include font-size(15px);
// 	}
// }

// h5 {
// 	@include font-size(15px);

// 	@include breakpoint-max(small) {
// 		@include font-size(14px);
// 	}
// }

// h6 {
// 	@include font-size(14px);

// 	@include breakpoint-max(small) {
// 		@include font-size(13px);
// 	}
// }

// hr {
// 	background-color: $color-dark-blue;
// 	border: 0;
// 	height: 1px;
// 	margin: $g-margin-basic;
// }

// p {
// 	margin: $g-margin-basic;
// 	overflow: hidden;
// }

// dfn,
// cite,
// em,
// i {
// 	font-style: italic;
// }

// blockquote {
// 	font-style: italic;
// 	padding: $g-padding-10px;
// 	margin: $g-margin-basic;
// }

// blockquote em,
// blockquote i,
// blockquote cite {
// 	font-style: normal;
// }

// blockquote strong,
// blockquote b {
// 	font-weight: $font-weight-bold;
// }

// address {
// 	font-style: italic;
// 	margin: $g-margin-basic;
// }

// code,
// kbd,
// tt,
// var,
// samp,
// pre {
// 	font-family: Inconsolata, monospace;
// }

// pre {
// 	background-color: $color-dark-blue;
// 	color: $color-dark-gray;
// 	padding: $g-padding-5px;
// 	margin: $g-padding-10px 0;
// 	display: inline-block;
// 	white-space: pre;
// 	white-space: pre-wrap;
// 	word-wrap: break-word;
// }

// code {
// 	background-color: $color-dark-blue;
// 	color: $color-dark-gray;
// 	padding: $g-padding-5px;
// 	margin: $g-padding-5px 0;
// 	display: inline-block;
// }

// abbr,
// acronym {
// 	border-bottom: 1px dotted inherit;
// 	cursor: help;
// }

// mark,
// ins {
// 	background-color: $color-dark-blue;
// 	color: $color-dark-gray;
// 	padding: $g-padding-5px;
// 	margin: $g-padding-5px 0;
// 	display: inline-block;
// 	text-decoration: none;
// }



// img {
// 	height: auto;
// 	max-width: 100%;
// 	vertical-align: middle;
// 	position: relative;
// }

// figure {
// 	margin: 0;
// }

// video {
// 	width: 100% !important;
// 	height: auto !important;
// }

@import "lists";
// @import "tables";
@import "accessibility";