// @function strip-units($number) {
//     @return $number / ($number * 0 + 1);
// }

@mixin button-basic-size($size: $g-button-size,
    $width: $g-button-size*3,
    $padding: 0 $g-button-size*1.5,
    $line-height: $g-button-size*$g-button-line-height,
    $border-width: none) {

    @if $width=='auto' {
        width: $width;
    }

    @else if $width==100% {
        width: 100%;
    }

    @else {
        width: $width;
    }

    @include font-size($g-button-font-size);

    @if ($border-width !=('none')) {
        $size-with-border: $size - (2 * $border-width);
        line-height: ($size-with-border * $g-button-line-height)+($border-width);
        padding: $padding;
        padding-top: $border-width;
    }

    @else {
        line-height: $line-height;
        padding: $padding;
    }
}

.o-button {
    @include button-basic-size($size: $g-button-size,
        $width: auto,
        $padding: 0 $g-button-size*1.5,
        $line-height: $g-button-size*$g-button-line-height,
        $border-width: none);
    $g-button-color: map-get($g-button-colors, white);
    background-color: map-get($g-button-color, "bg-color");
    color: map-get($g-button-color, "color");
    @include border-basic-style($border-width:$g-basic-border-width, $border-color: map-get($g-button-color, "border-color"));
    position: relative;
    overflow: hidden;
    display: inline-block;
    font-family: $g-font-primary;
    font-weight: $g-font-weight-medium;
    text-transform: uppercase;
    letter-spacing: 0.075em;
    border-radius: calculate-rem(1.5*$g-button-size);
    cursor: pointer;
    // outline: none;
    outline-offset: 0;
    text-align: center;
    text-decoration: none;
    box-sizing: content-box;
    z-index: 3;
    transition: color 0.5s ease 0.25s, border-color 0.5s ease 0.25s;

    &:before {
        -webkit-transition: 0.5s all ease;
        transition: top 0.5s ease 0.25s, background-color 0.5s ease 0.25s, bottom 0.5s ease 0.25s;
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        bottom: 50%;
        opacity: 1;
        content: '';
        z-index: -1;

        @if((lightness(map-get($g-button-color, "bg-color")) > 90) or (map-get($g-button-color, "bg-color")==transparent)) {

            background-color: map-get($g-button-color, "bg-color-hover");
        }

        @else {

            background-color: map-get($g-button-color, "bg-color-hover");

        }
    }

    &:hover,
    &:focus {
        @if((lightness(map-get($g-button-color, "bg-color")) > 90) or (map-get($g-button-color, "bg-color")==transparent)) {
            @include border-basic-style($border-width:$g-basic-border-width, $border-color:map-get($g-button-color, "bg-color-hover"));
            color: mix(map-get($g-button-color, "color"), $g-color-dark-gray, 75%);
        }

        @else {
            @include border-basic-style($border-width:$g-basic-border-width, $border-color: mix(map-get($g-button-color, "border-color"), $g-color-white, 75%));
            color: mix(map-get($g-button-color, "color"), $g-color-white, 75%);
        }

        &:before {
            -webkit-transition: 0.5s all ease;
            transition: 0.5s all ease;
            top: 0;
            bottom: 0;
            opacity: 1;


        }
    }


    @each $name,
    $g-button-color in $g-button-colors {

        &--#{$name} {
            background-color: map-get($g-button-color, "bg-color");
            color: map-get($g-button-color, "color");
            @include border-basic-style($border-width:$g-basic-border-width, $border-color: map-get($g-button-color, "border-color"));

            &:before {
                @if((lightness(map-get($g-button-color, "bg-color")) > 90) or (map-get($g-button-color, "bg-color")==transparent)) {
                    background-color: map-get($g-button-color, "bg-color-hover");
                }

                @else {
                    background-color: map-get($g-button-color, "bg-color-hover");
                }
            }

            &:hover,
            &:focus {
                @if((lightness(map-get($g-button-color, "bg-color")) > 90) or (map-get($g-button-color, "bg-color")==transparent)) {
                    @include border-basic-style($border-width:$g-basic-border-width, $border-color: map-get($g-button-color, "bg-color-hover"));
                    color: mix(map-get($g-button-color, "color"), $g-color-dark-gray, 75%);

                }

                @else {
                    @include border-basic-style($border-width:$g-basic-border-width, $border-color: map-get($g-button-color, "bg-color-hover"));
                    color: mix(map-get($g-button-color, "color"), $g-color-white, 75%);
                }

            }

        }
    }



    @include breakpoint-max(x-medium) {
        & {
            @include button-basic-size($size: ($g-button-size)-2, $width: auto)
        }
    }

    @include breakpoint-max(x-small) {
        & {
            @include button-basic-size($size: ($g-button-size)-2, $width: auto, $padding: 0 $g-button-size, )
        }
    }

    &--point {
        @include button-basic-size($width: $g-button-size*$g-button-line-height, $line-height: $g-button-size*$g-button-line-height, $padding:0);
        text-align: center;

        height: $g-button-size*$g-button-line-height;

        & .fa,
        & .fab,
        & .fad,
        & .fal,
        & .far,
        & .fas {
            @include font-size(16px);
            letter-spacing: normal;
            line-height: $g-button-size*$g-button-line-height;
        }

        @include breakpoint-max(x-medium) {
            & {
                @include button-basic-size($width: (($g-button-size)-2)*$g-button-line-height, $line-height: (($g-button-size)-2)*$g-button-line-height, $padding:0);


                & .fa,
                & .fab,
                & .fad,
                & .fal,
                & .far,
                & .fas {
                    line-height: (($g-button-size)-2)*$g-button-line-height;
                }

            }
        }

    }

    &--big {
        @include button-basic-size($size: $g-button-size-big);

        @include breakpoint-max(x-medium) {
            & {
                @include button-basic-size($size: ($g-button-size-big)-2)
            }
        }
    }

    &--small {
        @include button-basic-size($size: $g-button-size-small);

        @include breakpoint-max(x-medium) {
            & {
                @include button-basic-size($size: ($g-button-size-small)-2)
            }
        }
    }
}




.o-icon {
    $button-color-icon: map-get($g-button-colors-icon, white);
    width: 2em;
    height: 2em;
    border-radius: 2em;
    background-color: map-get($button-color-icon, "bg-icon-color");
    transform: translate3d(0, 0, 0);
    box-shadow: 0px 0px 0px 0px rgba(242, 186, 13, 0);
    transition: background-color 0.5s ease 0.25s;
    display: inline-block;
    @include font-size(37px);
    text-decoration: none;
    cursor: pointer;
    border: 0px;
    position: relative;


    &::after {
        text-align: center;
        padding-top: 0.25em;
        content: "";
        position: absolute;
        color: map-get($button-color-icon, "icon-color");
        font-family: 'FontAwesome';
        left: 0;
        top: 0;
        width: 2em;
        height: 2em;
        transform: translate3d(0, 0, 0);
        transition: color 0.5s ease 0.25s;
    }

    &:hover {
        &::after{
        color: mix($g-color-dark-gray, map-get($button-color-icon, "icon-color"), 15%);
        }

    }


    &--small {
        @include font-size(28px);
    }


    @include breakpoint-max('medium') {
        @include font-size(28px);

        &--small {
            @include font-size(24px);
        }
    }

    @include breakpoint-max('small') {
        @include font-size(24px);

        &--small {
            @include font-size(18px);
        }
    }

    &--arrow-top::after {
        content: "\f062";
    }

}

.o-icon-button {

    display: inline-block;
    @include font-size(37px);
    text-decoration: none;
    cursor: pointer;
    position: relative;
    margin-right: $g-margin-x1_5;
    padding-left: $g-padding-37px;
    transition: color 0.5s ease 0.25s;

    $button-color-icon: map-get($g-button-colors-icon, green);
    color: map-get($button-color-icon, "color");
    &:hover {
        color: map-get($button-color-icon, "color-hover");
    }

    &::before {
        content: "";
        position: absolute;
        left: 0;
        top: -0.25em;
        width: 2em;
        height: 2em;
        border-radius: 2em;
        background-color: map-get($button-color-icon, "bg-icon-color");
        transform: translate3d(0, 0, 0);
        box-shadow: 0px 0px 0px 0px rgba(242, 186, 13, 0);
        transition: background-color 0.5s ease 0.25s;
    }

    &::after {
        text-align: center;
        padding-top: 0.05em;
        content: "";
        position: absolute;
        color: map-get($button-color-icon, "icon-color");
        font-family: 'FontAwesome';
        left: 0;
        width: 2em;
        height: 2em;
        transform: translate3d(0, 0, 0);
        transition: color 0.5s ease 0.25s;
    }

    &--white{
        $button-color-icon: map-get($g-button-colors-icon, white);
        color: map-get($button-color-icon, "color");
        &:hover {
            color: map-get($button-color-icon, "color-hover");
        }
        &::before{
            background-color: map-get($button-color-icon, "bg-icon-color");
        }
        &::after{
            color: map-get($button-color-icon, "icon-color");
        }
    }

    &--blue{
        $button-color-icon: map-get($g-button-colors-icon, blue);
        color: map-get($button-color-icon, "color");
        &:hover {
            color: map-get($button-color-icon, "color-hover");
        }
        &::before{
            background-color: map-get($button-color-icon, "bg-icon-color");
        }
        &::after{
            color: map-get($button-color-icon, "icon-color");
        }
    }

    &--small {
        @include font-size(28px);
    }


    @include breakpoint-max('medium') {
        @include font-size(28px);

        &--small {
            @include font-size(24px);
        }
    }

    @include breakpoint-max('small') {
        @include font-size(24px);

        &--small {
            @include font-size(18px);
        }
    }



    &--phone::after {
        content: "\f095";
    }

    &--mail::after {
        content: "\f1d8";
    }

    &--animate {
        &::before {
            animation: animate-bg 3000ms infinite;
        }

        &::after {
            animation: animate-ring 3s .7s infinite;
        }
    }

    @keyframes animate-ring {
        0% {
            transform: translate3d(0em, 0, 0);
        }

        2% {
            transform: translate3d(0.05em, 0, 0);
        }

        4% {
            transform: translate3d(-0.05em, 0, 0);
        }

        6% {
            transform: translate3d(0.05em, 0, 0);
        }

        8% {
            transform: translate3d(-0.05em, 0, 0);
        }

        10% {
            transform: translate3d(0.05em, 0, 0);
        }

        12% {
            transform: translate3d(-0.05em, 0, 0);
        }

        14% {
            transform: translate3d(0.05em, 0, 0);
        }

        16% {
            transform: translate3d(-0.05em, 0, 0);
        }

        18% {
            transform: translate3d(0.05em, 0, 0);
        }

        20% {
            transform: translate3d(-0.05em, 0, 0);
        }

        22% {
            transform: translate3d(0.05em, 0, 0);
        }

        24% {
            transform: translate3d(-0.05em, 0, 0);
        }

        26% {
            transform: translate3d(0.05em, 0, 0);
        }

        28% {
            transform: translate3d(-0.05em, 0, 0);
        }

        30% {
            transform: translate3d(0.05em, 0, 0);
        }

        32% {
            transform: translate3d(-0.05em, 0, 0);
        }

        34% {
            transform: translate3d(0.05em, 0, 0);
        }

        36% {
            transform: translate3d(-0.05em, 0, 0);
        }

        38% {
            transform: translate3d(0.05em, 0, 0);
        }

        40% {
            transform: translate3d(-0.05em, 0, 0);
        }

        42% {
            transform: translate3d(0.05em, 0, 0);
        }

        44% {
            transform: translate3d(-0.05em, 0, 0);
        }

        46% {
            transform: translate3d(0em, 0, 0);
        }

    }
    $button-color-icon: map-get($g-button-colors-icon, green);
    $bg-icon-color: map-get($button-color-icon, "bg-icon-color");

    @keyframes animate-bg {

    
        0% {
            transform: translate3d(0, 0, 0) scale(1);

            box-shadow: 0px 0px 0px 0px rgba($bg-icon-color, 0);
        }

        25% {
            transform: translate3d(0, 0, 0) scale(1.1);

            box-shadow: 0px 0px 0px 0px rgba($bg-icon-color, 1);
        }

        75% {
            transform: translate3d(0, 0, 0) scale(1);

            box-shadow: 0px 0px 0px 0.5em rgba($bg-icon-color, 0);
        }

        100% {
            transform: translate3d(0, 0, 0) scale(1);
            box-shadow: 0px 0px 0px 0px rgba($bg-icon-color, 0);
        }

    }

}

// button,
// input[type="button"],
// input[type="reset"],
// input[type="submit"] {
//     @include button-basic-style;

//     &:hover {
//         @include button-basic-style-hover;
//     }
// }