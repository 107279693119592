/**transition**/
@mixin underline-style {
   position: relative;
   text-decoration: none;
   display: inline-block;

   &:after {
      content: '';
      display: block;
      width: 100%;
      height: 0.1em;
      background: $color-light-red;
      transform: scaleX(0);
      transition: transform 250ms ease-in-out;
      transform-origin: 100% 50%
   }

   &:hover:after,
   &:focus:after,
   &:active:after {
      transform: scaleX(1);
      transform-origin: 0 50%;
      height: 0.1em;
   }
}