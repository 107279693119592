@import "buttons";
@import "alerts";


@mixin input-basic-style {
    margin-top: $g-margin-x_5;
    margin-bottom: $g-margin-x_5;
    @include color($g-basic-text-color);
    @include background-color($g-color-white, .8);
    @include font-size($g-button-size);
    font-family: $g-font-primary;
    font-weight: $g-font-weight-regular;
    letter-spacing: 0;
    line-height: $g-button-size;
    text-align: left;
    text-decoration: none;
    display: inline-block;
    position: relative;
    width: 100%;
    max-width: 650px;
    height: $g-button-size*3;
    padding: $g-padding-x1 $g-padding-x1;
    border: 2px solid $g-color-light-gray;
    border-radius: calculate-rem(1.5*$g-button-size);
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    -webkit-backface-visibility: hidden;
    box-sizing: border-box;
    outline: none;
    outline-offset: 0;
    transition: background-color 0.5s;

    &:-internal-autofill-previewed,
    &:-internal-autofill-selected {
        @include background-color;
    }

    &:focus {
        @include background-color($g-color-white);
        outline: none;
        outline-offset: 0;
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
    }

    &--error {
        @include background-color($g-color-white);
    }

}



.c-cform {
    $parent: #{&};
    @include section-flex-basic-style;
    justify-content: center;

    &__row {
        @include section-flex-basic-style;
        flex-direction: column;
        padding: $g-padding-t $g-padding-t;

          @include breakpoint-max('x-medium') {
               padding: 0 $g-padding-t;
          }

       @include breakpoint-max('medium') {
           padding: 0 $g-padding-t;

        }

        @include breakpoint-max('small') {
           padding: 0 $g-padding-t;

        }


        &--1-2 {
            width: 50%;
        }

        &--1-0 {
            width: 100%;
            justify-content: flex-end;
        }

        @include breakpoint-max('x-medium') {
            &--1-2 {
                width: 75%;
            }
        }
        @include breakpoint-max('normal') {
            &--1-2 {
                width: 100%;
            }
        }

    }

    &__input {
        @include input-basic-style;


        &::-webkit-input-placeholder {
            color: transparent;
        }

        &:-moz-placeholder {
            color: transparent;
        }

        &:-ms-input-placeholder {
            color: transparent;
        }

        &::placeholder {
            color: transparent;
        }
    }


    &__textarea {
        @include input-basic-style;
        min-height: 13.5em;
        resize: vertical;

        &::-webkit-input-placeholder {
            color: transparent;
        }

        &:-moz-placeholder {
            color: transparent;
        }

        &:-ms-input-placeholder {
            color: transparent;
        }

        &::placeholder {
            color: transparent;
        }
    }

    &__submit {
        // @include button-basic-size($size: $g-button-size,
        //     $width: auto,
        //     $padding: 0 $g-button-size*1.5,
        //     $line-height: $g-button-size*$g-button-line-height,
        //     $border-width: none);
        // $g-submit-button-color: map-get($g-button-colors, ghost-white);
        // background-color: map-get($g-submit-button-color, "bg-color");
        // color: map-get($g-submit-button-color, "color");
        // @include border-basic-style($border-width:$g-basic-border-width, $border-color: map-get($g-submit-button-color, "border-color"));
        // position: relative;
        // overflow: hidden;
        // display: inline-block;
        // font-family: $g-font-primary;
        // font-weight: $g-font-weight-medium;
        // text-transform: uppercase;
        // letter-spacing: 0.25em;
        // border-radius: calculate-rem(1.5*$g-button-size);
        // cursor: pointer;
        // // outline: none;
        // outline-offset: 0;
        // text-align: center;
        // text-decoration: none;
        // box-sizing: content-box;
        // transition: color 0.5s ease 0.25s, border-color 0.5s ease 0.25s, background-color 0.5s ease 0.25s;
        margin-top: $g-margin-x_5;
        margin-bottom: $g-margin-x_5;
        align-self: flex-end;

        // &:hover,
        // &:focus {
        //     background-color: mix($g-color-green, $g-color-white, 90%);
        //     color: $g-color-white;
        //     @include border-basic-style($border-width:$g-basic-border-width, $border-color: mix($g-color-green, $g-color-white, 90%));

        // }
    }

    &__checkbox {
        display: none;
    }


    &__checkbox+&__checkbox-label {
        cursor: pointer;
    }


    &__checkbox+&__checkbox-label:before {
        content: "";
        @include border-basic-style($border-color:$g-color-white);
        border-radius: $g-width-x_25;
        display: inline-block;
        width: $g-width-x1_5;
        height: $g-width-x1_5;
        line-height: $g-width-x1_5;
        margin-right: $g-margin-x_25;
        vertical-align: bottom;
        color: transparent;
        transition: .2s;
        text-align: center;
    }

    &__checkbox+&__checkbox-label:active:before {
        transform: scale(0.75);
    }

    &__checkbox:checked+&__checkbox-label:before {
        content: "\2714";
        background-color: $g-color-green;
        border-color: $g-color-green;
        color: $g-color-white;
    }

    &__checkbox:disabled+&__checkbox-label:before {
        transform: scale(1);
        border-color: $g-color-light-gray
    }

    &__checkbox:checked:disabled+&__checkbox-label:before {
        transform: scale(1);
        background-color: $g-color-light-gray;
        border-color: $g-color-light-gray;
    }

}


.js-holder-info {
    position: relative;
    width: 100%;
    $parent: #{&};

    &__span {
        color: $g-basic-text-color;
        pointer-events: none;
        position: absolute;
        transition: 0.2s;
        margin-top: $g-margin-x_5;
        margin-bottom: $g-margin-x_5;
        padding: $g-padding-x1;
        transform: translateY(0);
        z-index: 10;

        #{$parent}.is-focussed &,
        #{$parent}.is-value &,
        #{$parent}.is-error-value &
         {
            transform: translateX($g-font-size-x-small) translateY($g-margin-x_75*(-1));
            padding: $g-margin-x_5 $g-padding-x_75;
            border-radius: $g-padding-x1;
            display: inline-block;
            text-transform: uppercase;
            letter-spacing: $g-letter-spacing;
            @include font-size($g-font-size-x-small);
            line-height: $g-font-size-x-small;
        }

                #{$parent}.is-focussed & {
            background-color: $g-color-blue;
            color: $g-color-white;
        }


        #{$parent}.is-value:not(.is-focussed) & {
            background-color: $g-color-green;
            color: $g-color-white;
        }


        #{$parent}.is-error-value & {
            background-color: $g-color-red;
            color: $g-color-white;
        }

        #{$parent}.is-value.is-error-value:not(.is-focussed) & {
            background-color: $g-color-red;
            color: $g-color-white;
        }


    }


}



$g-input-size: 18px;
$g-button-size-small: 14px;
$g-button-size-big: 22px;
$g-button-line-height: 2.5;
$g-button-color: map-get($g-button-colors, light-gray);





// input,
// select,
// textarea {
//     margin: 0;
//     vertical-align: middle;
//     -webkit-box-shadow: none;
//     -moz-box-shadow: none;
//     box-shadow: none;
// }

// input[type="text"],
// input[type="email"],
// input[type="url"],
// input[type="password"],
// input[type="search"],
// input[type="number"],
// input[type="tel"],
// input[type="range"],
// input[type="date"],
// input[type="month"],
// input[type="week"],
// input[type="time"],
// input[type="datetime"],
// input[type="datetime-local"],
// input[type="color"],
// textarea {
//     @include input-style;

//     &:focus {
//         @include input-style-focus;
//     }
// }

// textarea {
//     height: 144px;
//     resize: vertical;
// }

// input[type="checkbox"],
// input[type="radio"] {
//     width: 100%;
//     height: 100%;
// }

// input[type="search"],
// input[type="search"]:active {
//     appearance: none;
//     -webkit-appearance: none;
//     -moz-appearance: none;
// }

// input[type="search"] {
//     &::-webkit-search-decoration {
//         display: none;
//     }
// }

// input[type=number]::-webkit-inner-spin-button,
// input[type=number]::-webkit-outer-spin-button {
//     appearance: none;
//     -webkit-appearance: none;
//     -moz-appearance: none;
//     margin: 0;
// }

// input[type=number] {
//     -moz-appearance: textfield;
// }

// optgroup {
//     font-style: normal;
//     color: $color-dark-gray;
// }

// optgroup:before {
//     content: attr(label);
//     display: block;
//     padding: 10px 0 5px;
// }

// option {
//     @include font-size($font-size-small)
// }

// input[type="file"] {
//     display: block;
//     padding: $g-padding-x_5 $g-padding-x1;
//     @include font-basic-size;
//     border: none;
//     @include border-basic-style;
// }

// .check,
// .radio {
//     padding: 0 0 25px !important;
// }

// .check label,
// .radio label {
//     padding: 3px 0 0;
//     float: left;
//     max-width: 87%;
// }

// fieldset {
//     display: inline-block;
//     width: 100%;
// }

// label {
//     display: inline-block;
//     position: relative;
//     @include font-basic-size;
//     @include font-basic-style;
//     color: inherit;
//     cursor: pointer;
// }