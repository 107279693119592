/**background**/
@mixin bg-cover {
  background-repeat: no-repeat;
  background-position: center;
  -ms-background-size: cover;
  -o-background-size: cover;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  background-size: cover;
  position: relative;
}

@mixin bg-blend($bg-color: $g-color-background, $opacity:.9) {
  background-color: rgba($bg-color, $opacity);
  background-blend-mode: luminosity;
  transition: background-color 0.5s;
}

@mixin img-blend {
  mix-blend-mode: luminosity;
}

@mixin bg-blend-color($bg-color: $g-color-background, $opacity:.9) {
  background-color: rgba($bg-color, $opacity);
  transition: background-color 0.5s;
}

@mixin background-color($background-color: $g-color-white, $opacity:false) {
  @if($opacity) {
    background-color: rgba($background-color, $opacity);
  }

  @else {
    background-color: $background-color;
  }

}

@mixin bg-top-shadow {
  box-shadow: inset 0px 10px 10px 0px rgba(199, 208, 216, 0.6);
}

// .t {

//   @each $name,
//   $bg-color in $g-bg-colors {

//     &-#{$name} {
//       @include background-color($bg-color);
//       color: set-text-color($bg-color);

//       &-transparent {
//         @include background-color($bg-color, .8);
//       }
//     }
//   }

// }