.c-page-footer {
    background-color: $g-color-blue;
    position: relative;


    &__bg-wrapper {
        position: absolute;
        width: 100%;
        height: auto;
        min-height: 100%;
        max-height: 100%;
        top: 0;
        bottom: 0;
    }

    &__bg {
        @include bg-cover;
        background-color: $g-color-blue;
        background-image: url(../images/img-1.jpg);
        overflow: hidden;
        width: 65%;
        height: inherit;
        min-height: inherit;
        max-height: inherit;
        margin: 0 auto;
        margin-left: 35%;

        @include breakpoint-max('small') {
            width: 75%;
            margin-left: 25%;
        }

        @include breakpoint-max('x-small') {
            width: 85%;
            margin-left: 15%;
        }
    }

    .o-triangle-topleft {
        width: 0;
        height: 0;
        border-top: 900px solid $g-color-blue;
        border-right: 300px solid transparent;
        position: absolute;
        top: 0;
        left: 0;
    }

    &__wrapper {
        @include section-basic-style($padding: 0em);
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: space-between;

        @include breakpoint-max('large') {
            padding: 0em $g-padding-t;

        }
    }

    &__text-wrapper {
        color: $g-color-white;
        padding: $g-padding-l;
        width: 50%;


        @include breakpoint-max('large') {
            padding: $g-padding-n;
            width: 55%;
        }

        @include breakpoint-max('x-medium') {
            padding: $g-padding-n;
            width: 65%;
        }

        @include breakpoint-max('medium') {
            padding: $g-padding-n;
            width: 75%;
        }

        @include breakpoint-max('normal') {
            padding: $g-padding-s;
            width: 85%;
        }

        @include breakpoint-max('small') {
            padding: $g-padding-t;
            width: 100%;
        }

        @include breakpoint-max('x-small') {
            text-align: center;
            padding: $g-padding-t;
        }

    }


    &__title {
        color: $g-color-white;
        font-weight: $g-font-weight-light;
        @include font-size(48px);
        line-height: 1.2;
        text-align: left;
        text-transform: uppercase;


        @include breakpoint-max('medium') {
            @include font-size(42px);
        }

        @include breakpoint-max('normal') {
            @include font-size(36px);
        }

        @include breakpoint-max('small') {
            @include font-size(32px);
        }

        @include breakpoint-max('x-small') {
            text-align: center;
        }

    }

    

}


.c-icon{
    &__button {
    padding: $g-padding-n 0 $g-padding-n 0;
display:inline-block;

    @include breakpoint-max('small') {
        padding: $g-padding-s 0 $g-padding-s 0;
    }


    @include breakpoint-max('x-small') {
        padding: $g-padding-t 0 $g-padding-t 0;
        text-align: center;
    }
    }

}

.c-page-header {
    background-color: $g-color-white;
    position: relative;

    &--home {

        height: 100vh;
        min-height: $g-min-height-home;
        max-height: 900px;

        @include breakpoint-max('small') {
            height: auto;
        }
    }

    &__bg-wrapper {
        position: absolute;
        width: 100%;
        height: inherit;
        min-height: inherit;
        max-height: inherit;
    }

    &__bg {
        @include bg-cover;
        background-color: $g-color-white;
        background-image: url(../images/img-1.jpg);
        overflow: hidden;
        width: 65%;
        height: inherit;
        min-height: inherit;
        max-height: inherit;
        margin: 0 auto;
        margin-left: 35%;

        @include breakpoint-max('small') {
            width: 75%;
            margin-left: 25%;
        }

        @include breakpoint-max('x-small') {
            width: 85%;
            margin-left: 15%;
        }
    }

    .o-triangle-topleft {
        width: 0;
        height: 0;
        border-top: 900px solid white;
        border-right: 300px solid transparent;
        position: absolute;
        top: 0;
        left: 0;
    }

    &__wrapper {
        @include section-basic-style($padding: 0em);
        height: inherit;
        min-height: inherit;
        max-height: inherit;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: space-between;

        @include breakpoint-max('large') {
            padding: 0em $g-padding-t;

        }



    }

    &__logo-wrapper {
        z-index: 9;
        align-self: flex-start;
        padding: $g-padding-s $g-padding-t;

        @include breakpoint-max('small') {
            padding: $g-padding-s $g-padding-x_5;
        }

        @include breakpoint-max('x-small') {
            padding: $g-padding-s 0;
            align-self: center;
        }
    }

    &__logo {
        @include font-size(48px);
        font-weight: $g-font-weight-extra-bold;
        font-style: italic;
        color: $g-color-blue;
        text-decoration: none;

        &:hover {
            color: mix($g-color-blue, $g-color-white, 75%);
        }

        @include breakpoint-max('medium') {
            @include font-size(42px);
        }

        @include breakpoint-max('normal') {
            @include font-size(36px);
        }

        @include breakpoint-max('small') {
            @include font-size(32px);
        }


    }

    &__text-wrapper {
        padding: $g-padding-l;
        width: 50%;


        @include breakpoint-max('large') {
            padding: $g-padding-n;
            width: 55%;
        }

        @include breakpoint-max('x-medium') {
            padding: $g-padding-n;
            width: 65%;
        }

        @include breakpoint-max('medium') {
            padding: $g-padding-n;
            width: 75%;
        }

        @include breakpoint-max('normal') {
            padding: $g-padding-s;
            width: 85%;
        }

        @include breakpoint-max('small') {
            padding: $g-padding-t;
            width: 100%;
        }

        @include breakpoint-max('x-small') {
            text-align: center;
            padding: 0em;
        }

    }

    &__title {
        color: $g-color-dark-gray;
        font-weight: $g-font-weight-light;
        @include font-size(48px);
        line-height: 1.2;
        text-align: left;
        text-transform: uppercase;


        @include breakpoint-max('medium') {
            @include font-size(42px);
        }

        @include breakpoint-max('normal') {
            @include font-size(36px);
        }

        @include breakpoint-max('small') {
            @include font-size(36px);
        }

        @include breakpoint-max('x-small') {
            text-align: center;
        }

    }

    &__title-top {
        color: $g-color-blue;
        @include font-size(32px);
        text-transform: none;
        display: block;

        @include breakpoint-max('medium') {
            @include font-size(28px);
        }

        @include breakpoint-max('normal') {
            @include font-size(24px);
        }

        @include breakpoint-max('small') {
            @include font-size(22px);
        }
    }

    &__buttons {
        padding: $g-padding-s $g-padding-t $g-padding-l $g-padding-t;


        @include breakpoint-max('small') {
            padding: $g-padding-s $g-padding-x_5 $g-padding-l $g-padding-x_5;
        }


        @include breakpoint-max('x-small') {
            padding: $g-padding-s 0 $g-padding-l 0;
            text-align: center;
            margin-left: -2.5em;
        }
    }

}





.c-header {
    margin-bottom: $g-margin-x_5;

    @include breakpoint-max(small) {
        & {
            margin-bottom: 0;
        }
    }

    &__title {
        @include font-h1-basic-style;
        @include font-size(38px);
        margin-top: $g-margin-x_5;
        margin-bottom: $g-margin-x_5;

        @include breakpoint-max('small') {
            @include font-size(36px);
        }

        @include breakpoint-max('x-small') {
            @include font-size(32px);
        }

        @include breakpoint-max('xx-small') {
            @include font-size(28px);
        }
    }



    &__subtitle {
        @include font-hx-basic-style;
        @include font-size(36px);
        margin-top: $g-margin-x_5;
        margin-bottom: $g-margin-x1;

        @include breakpoint-max('small') {
            @include font-size(32px);
        }

        @include breakpoint-max('x-small') {
            @include font-size(28px);
        }

        @include breakpoint-max('xx-small') {
            @include font-size(24px);
        }
    }
}

.o-header {
    @include font-hx-basic-style;
    //@include font-size($g-font-size-large);
    margin-top: $g-margin-x_5;
    margin-bottom: $g-margin-x1;

    // @include breakpoint-max('small') {
    //     @include font-size($g-font-size-x-medium);
    // }

    // @include breakpoint-max('x-small') {
    //     @include font-size($g-font-size-medium);
    // }

    // @include breakpoint-max('xx-small') {
    //     @include font-size($g-font-size-normal);
    // }
}

.c-box-i {
    @include section-flex-basic-style;

    &__icon {
        width: 74px;

        @include breakpoint-max('small') {
            width: 74px*0.75;
        }
    }

    &__info {
        @include font-size($g-font-size-medium);
    }

}


.o-img {
    display: inline-block;
    position: relative;

    &__item {
        max-width: 100%;
        height: auto;
        display: block;
    }

    &--left {
        margin-left: -74px;
    }

    &--right {
        margin-right: -74px;
    }

}





.o-go-top {
    position: absolute;
    right: 0;
    top: 0;
    transform: translateY($g-margin-x1*(-1));

    @include breakpoint-between('large', 'large-plus') {
        right: $g-padding-n;
    }

    @include breakpoint-max('large') {
        right: $g-padding-t;
    }
}


.o-google-map {
    width: 100%;
    height: $g-min-height-map;

    @include breakpoint-max(x-small) {
        & {
            height: $g-min-height-map;
        }
    }
}

.c-cookies-popup-bg {
    width: 100%;
    height: 100%;
    z-index: 99;
    position: fixed;
    top: 0;
    left: 0;
    @include background-color($background-color: $g-color-white, $opacity:0.75);
}

@keyframes popupScale {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.c-cookies-popup {
    @include section-flex-basic-style;
    flex-wrap: wrap;
    justify-content: center;
    align-content: space-between;
    z-index: 100;
    color: $g-color-dark-gray;
    background-color: $g-color-white;
    position: fixed;
    margin: 0;
    padding: $g-padding-x1;
    top: 50%;
    left: 50%;
    width: 90%;
    max-width: 500px;
    height: auto;
    max-height: 90%;
    min-height: 200px;
    transform: translate(-50%, -50%);
    overflow-y: auto;
    visibility: visible;
    box-shadow: 0 1px $g-width-x1_5 1px mix($g-color-light-gray, $g-color-dark-gray, 75%);

    &--hide {
        animation: popupScale 1s ease 1 forwards;
    }

    &__content {
        text-align: center;
    }

    &__button {
        &--close {
            margin-left: auto;
            cursor: pointer;

            & .icon-close {
                width: 19px;
                height: 19px;
                margin: 0 auto;
                position: relative;

                &:before,
                &:after {
                    position: absolute;
                    left: 9px;
                    content: ' ';
                    height: 20px;
                    width: 2px;
                    background-color: mix($g-color-light-gray, $g-color-dark-gray, 50%);
                    transition: background-color 0.5s ease 0.25s;
                }

                &:hover {

                    &:before,
                    &:after {
                        background-color: $g-color-dark-gray;
                    }
                }

                &:before {
                    transform: rotate(45deg);
                }

                &:after {
                    transform: rotate(-45deg);
                }
            }
        }

        &--ok,
        &--no {
            margin: $g-margin-x_5 auto;
        }
    }
}

.js-cookies-popup-open {
    overflow: auto;
    min-height: 370px;
}